<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑       永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2022-01-04 15:05:03
 * @LastEditTime: 2022-11-03 14:46:27
 * @Description: 导航页签
 * @Param:
 * @FilePath: \BusinessPlatform\src\components\header\navTabs.vue
 -->

<template>
    <div class="navTabs" v-if="navTabsData.length > 0">
        <el-tabs
            type="card"
            :closable="navTabsData.length > 1"
            v-model="currentNavTabs"
            @tab-click="tabClick"
            @tab-remove="removeTab"
            @contextmenu.prevent.native="rightClickFun($event)"
        >
            <el-tab-pane
                v-for="item in navTabsData"
                :key="item.name"
                :label="item.title"
                :name="item.name"
            >
                <span slot="label">
                    {{ item.title }}
                </span>
            </el-tab-pane>
        </el-tabs>
        <ul
            v-show="rightFlag"
            :style="{left: left +'px',top: top + 'px'}"
            class="contextmenu"
        >
            <!-- <li @click="closeOtherTabs('left')">关闭左边</li> -->
            <li @click="closeOtherTabs('currently')" v-if="navTabsData.length !== 1">
                关闭页签
            </li>
            <li @click="closeOtherTabs('right')">
                关闭右边
            </li>
            <li @click="closeOtherTabs('other')">
                关闭其他
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'nav-tabs',
    components: {},
    props: {},
    data() {
        return {
            currentNavTabs: '',
            navTabsData: [],
            currName: '/homePage',
            rightFlag: false,
            left: 0,
            top: 0,
            innerText: '',
        };
    },
    computed: {},
    watch: {
        rightFlag(value) {
            if (this.rightFlag) {
                document.body.addEventListener('click', this.closeContextMenu);
            } else {
                document.body.removeEventListener('click', this.closeContextMenu);
            }
        },
    },
    created() {
        this.addNavTabs(this.$route);
    },
    mounted() {},
    destroyed() {},
    methods: {
        // 关闭其它标签页
        closeOtherTabs(type) {
            if (type === 'currently') {
                this.removeTab(this.navTabsData.filter(item => this.innerText.replaceAll(' ', '') === item.title.replaceAll(' ', ''))[0].name);
            } else if (type === 'right') {
                let index = 0;
                this.navTabsData.forEach((item, i) => {
                    if (item.title.replaceAll(' ', '') === this.innerText.replaceAll(' ', '')) {
                        index = i;
                    }
                });
                console.log(index);
                this.navTabsData = this.navTabsData.splice(0, index + 1);
                this.currentNavTabs = this.navTabsData[this.navTabsData.length - 1].name;
                this.currName = this.navTabsData[this.navTabsData.length - 1].name;
                this.$router.push({ path: this.navTabsData[this.navTabsData.length - 1].name });
            } else if (type === 'other') {
                this.navTabsData = this.navTabsData.filter(item => item.title.replaceAll(' ', '') === this.innerText.replaceAll(' ', ''));
                this.currentNavTabs = this.navTabsData[this.navTabsData.length - 1].name;
                this.currName = this.navTabsData[this.navTabsData.length - 1].name;
                this.$router.push({ path: this.navTabsData[this.navTabsData.length - 1].name });
            }
            this.rightFlag = false;
        },
        // 关闭contextMenu
        closeContextMenu() {
            this.rightFlag = false;
        },
        rightClickFun(event) {
            console.log(event);
            this.rightFlag = true;
            this.left = event.clientX;
            this.top = event.clientY + 10;
            this.innerText = event.srcElement.innerText;
        },
        tabClick(tab) {
            const new_path = this.navTabsData[tab.index].name;
            this.$router.push({ path: new_path });
        },
        removeTab(targetName) {
            const tabs = this.navTabsData;
            let activeName = this.currentNavTabs;
            if (activeName === targetName) {
                tabs.forEach((tab, index) => {
                    if (tab.name === targetName) {
                        const nextTab = tabs[index + 1] || tabs[index - 1];
                        if (nextTab) {
                            activeName = nextTab.name;
                        }
                    }
                });
            }
            this.currentNavTabs = activeName;
            this.navTabsData = tabs.filter(tab => tab.name !== targetName);

            this.$router.push({ path: activeName });
        },

        // 添加导航页签
        addNavTabs(data) {
            const row = this.navTabsData.filter(item => item.name === data.path);
            if (row.length === 0) {
                const tabsData = {
                    title: data.meta.title,
                    name: data.path,
                };
                this.navTabsData.push(tabsData);
            }
            this.currentNavTabs = data.path;
        },
    },
};
</script>
<style lang="stylus" scoped>
.navTabs
    margin: 0.15rem 0.15rem 0
    background: #F7F8FD;
    >>>.el-tabs
        height 0.4rem
        .el-tabs__header
            height 100%
            margin-bottom: 0
            border none
            .el-tabs__nav
                border none
            .el-tabs__nav-next,
            .el-tabs__nav-prev
                line-height: 0.4rem
            .el-tabs__item
                color #666666
                height 0.4rem
                font-size 0.16rem
                line-height 0.37rem
                border-bottom 3px solid #F7F8FD;
                &.is-active
                    color #006EFF
                    background rgba(0, 110, 255, 0.04)
                    border-bottom-color #006EFF
                    font-weight bold
                .el-icon-close
                    vertical-align text-top
    .contextmenu {
        width: 100px;
        margin: 0;
        border: 1px solid #ccc;
        background: #fff;
        z-index: 3000;
        position: absolute;
        list-style-type: none;
        padding: 5px 0;
        border-radius: 4px;
        font-size: 14px;
        color: #333;
        box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.2);
    }
    .contextmenu li {
        margin: 0;
        padding: 7px 16px;
    }
    .contextmenu li:hover {
        background: #f2f2f2;
        cursor: pointer;
    }
</style>